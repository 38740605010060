<template>
  <div>
    <a-config-provider :locale="zh_CN">
      <a-card>
        <a-row>
          <a-col :span="8">
            <a-radio-group v-model="Modevalue" @change="ModeChange" style="margin-left: 25px;">
              <a-radio :value="1">
                {{l('hour')}}
              </a-radio>
              <a-radio :value="2">
                {{l('day')}}
              </a-radio>
            </a-radio-group>
          </a-col>
          <a-col :span="16" style="text-align: right" v-if="value==true">
            <a-date-picker v-model="date" @change="onChange" style="margin-right: 10px" :allowClear="false"/>
            <a-time-picker :default-open-value="moment('00:00:00', 'HH:mm:ss')"  format="HH" style="margin-right: 10px" :placeholder="l('WEStartDate')" @change="onChange1"/>
            <a-time-picker :default-open-value="moment('00:00:00', 'HH:mm:ss')"  format="HH" :placeholder=" l('WEEndDate')" @change="onChange2"/>
<!--            <a-button type="primary" @click="create()" style="margin-left: 10px;">-->
<!--              <a-icon type="plus"/>-->
<!--              {{l('Create')}}-->
<!--            </a-button>-->
          </a-col>
          <a-col :span="16" style="text-align: right" v-if="value==false">
            <a-month-picker v-model="SelectMonthTime" @change="onChange3" :allowClear="false"/>
          </a-col>
          <a-col :span="24" style="margin-top: 20px">
            <a-spin :spinning="isLoading">
              <div style="width:100%;overflow-x: scroll;overflow-y: scroll;height: 100%" :style="styObj">
                <table v-if="value==true" style="text-align: center">
                  <th style="border:1px solid #e8e8e8;height: 50px;text-align: center;vertical-align:middle;width: 160px;height: 50px" class="body1">{{l('hour')}}</th>
                  <th v-for="item in hour" :title="item" style="border:1px solid #e8e8e8;vertical-align:middle;width: 60px;height: 50px" class="body">{{item}}</th>
                  <tr v-for="item in table">
                    <td style="border:1px solid #e8e8e8;width: 160px;height: 50px" class="body1">{{item.pointCheckMonthView[0].auditPointName}}</td>
                    <td v-for="items in item.mont" style="border:1px solid #e8e8e8;width: 60px;" class="body"><a @click="monthly(items)">{{items.count ? items.count : undefined}}</a></td>
                  </tr>
                </table>
                <table v-if="value==false" style="text-align: center">
                  <th style="border:1px solid #e8e8e8;height: 50px;text-align: center;vertical-align:middle;width: 160px;height: 50px" class="body1">{{l('day')}}</th>
                  <th v-for="item in month" :title="item" style="border:1px solid #e8e8e8;vertical-align:middle;width: 60px;height: 50px" class="body">{{item}}</th>
                  <tr v-for="item in Table">
                    <td style="border:1px solid #e8e8e8;width: 160px;height: 50px" class="body1">{{item.pointCheckMonthView[0].auditPointName}}</td>
                    <td v-for="items in item.mont" style="border:1px solid #e8e8e8;width: 60px;" class="body"><a @click="monthly(items)">{{items.count ? items.count : undefined}}</a></td>
                  </tr>
                </table>
              </div>
            </a-spin>
          </a-col>
        </a-row>

      </a-card>
    </a-config-provider>
  </div>
</template>

<script>
import {
  AppComponentBase
} from "@/shared/component-base";
import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
import {
  PointCheckServiceProxy
} from '../../../shared/service-proxies';
import CreatErrorCode from "../organization-structure/new-template/new-template";
import moment from "moment";
import { ModalHelper } from '@/shared/helpers';
import monthly from "./view/monthly-view"


let _this;
export default {
  name: "month-view",
  mixins: [AppComponentBase],
  components: {
    CreatErrorCode,
    monthly,
  },
  created() {
    _this = this;
    this.PointCheckServiceProxy = new PointCheckServiceProxy(this.$apiUrl, this.$api);
  },
  mounted() {
    // this.getData();
    this.function();

    window.addEventListener('resize', this.changeHeight);
    this.changeHeight();
  },
  data() {
    return {
      zh_CN,
      isEdit: false,
      isLook: false,
      isLoading: false,
      Modevalue: 1,
      hourOrDay: 1,
      selectDayTime: undefined,
      aData: undefined,
      date: undefined,
      timeRangeStartTime: undefined,
      timeRangeEndTime: undefined,
      SelectMonthTime: undefined,
      data:{
        hourOrDay: undefined,
        selectDayTime:undefined,
        SelectMonthTime: undefined,
        timeRangeStartTime: undefined,
        timeRangeEndTime: undefined,
      },
      hour:[],
      month: [],
      table: undefined,
      startTime: undefined,
      endTime: undefined,
      i: undefined,
      value:true,
      tableData: undefined,
      mmm: undefined,
      mmmm: 24,
      mon: [],
      p: undefined,
      q: undefined,
      tabled: undefined,
      DATA:[],
      Table: undefined,
      Hour: [],
      styObj:{
        height:0
      },
      HOUR: [],
      MONTH: {},
      MONTHDATA: undefined,

    }
  },
  methods: {
    moment,
    //div高度
    changeHeight(){
      this.styObj.height=window.innerHeight-199+'px';
    },
    create() {
      this.timeRangeStartTime = undefined;
      this.timeRangeEndTime = undefined;
      this.getData();
    },
      getData() {
      console.log(this.MONTH)
        this.data.hourOrDay = this.hourOrDay;
        this.data.selectDayTime = this.selectDayTime;
        this.data.SelectMonthTime = this.SelectMonthTime;
        this.data.timeRangeStartTime = this.timeRangeStartTime;
        this.data.timeRangeEndTime = this.timeRangeEndTime;
        console.log(this.data);
        this.isLoading = true;
        this.PointCheckServiceProxy.pointCheckMonthView(
          this.data
        ).finally(() => {
          this.isLoading = false;
        }).then(res => {
          console.log(res);
          this.table = res;
          if (this.hourOrDay==1){
            res.forEach((item, index) => {
              console.log(item);
              this.tableData = item.pointCheckMonthView;
              console.log(this.tableData);
              this.tableData.forEach((items, index) => {
                items["mont"] = "16";
                this.Hour = [];
                console.log(items);
                items.time=items.time?moment(items.time).format("HH"):"";
                let today = parseInt(items.time);
                let t =this.mmmm;
                if(this.timeRangeEndTime==undefined&&this.timeRangeStartTime==undefined){
                  for (let m=0;m<t;m++){
                    if(m==today){
                      this.p = items.chirdPointCheckMonthViewDto;
                    }else {
                      this.p = 0;
                    }
                    this.Hour.push(this.p);

                  }
                }else if(this.timeRangeEndTime!=undefined&&this.timeRangeStartTime!=undefined){
                  let f = parseInt(this.startTime);
                  console.log(f);
                  let y = f+t;
                  console.log(y);
                  for (let m=f;m<y;m++){
                    if(m==today){
                      this.p = items.chirdPointCheckMonthViewDto;
                    }else {
                      this.p = 0;
                    }
                    this.Hour.push(this.p);

                  }
                  console.log(this.Hour);
                }

                console.log(this.Hour);
                items.mont = this.Hour;
              });

            });
            this.table = res;
            console.log(this.table);
            this.table.forEach((item, index) => {
              item["mont"] = "16";
              console.log(item);
              this.DATA = item.pointCheckMonthView[0].mont;
              this.tabled = item.pointCheckMonthView;
              this.tabled.forEach((items, index) => {
                console.log(items)
                let t =this.mmmm;
                for(let i=0;i<t;i++){
                  if(items.mont[i]>0){
                    this.DATA[i]=items.mont[i];
                  }
                }
                item.mont = this.DATA;
              });
            });
            console.log(this.table)
            this.Table = this.table;
          }else {
            res.forEach((item, index) => {
              console.log(item);
              this.tableData = item.pointCheckMonthView;
              console.log(this.tableData);
              this.tableData.forEach((items, index) => {
                items["mont"] = "16";
                this.mon = [];
                console.log(items);
                items.time=items.time?moment(items.time).format("DD"):"";
                let today = parseInt(items.time);
                let t =this.mmm;
                for (let m=1;m<t+1;m++){
                  if(m==today){
                    this.p = items.chirdPointCheckMonthViewDto;
                    // this.MONTH["p"] = this.p;
                  }else {
                    this.p = 0;
                    // this.MONTH["p"] = this.p;
                  }
                  this.mon.push(this.p);

                }
                items.mont = this.mon;
              });

            });
            this.table = res;
            console.log(this.table);
            this.table.forEach((item, index) => {
              item["mont"] = "16";
              console.log(item);
              this.DATA = item.pointCheckMonthView[0].mont;
              this.tabled = item.pointCheckMonthView;
              this.tabled.forEach((items, index) => {
                console.log(items)
                let t =this.mmm;
                for(let i=0;i<t;i++){
                  if(items.mont[i]!=0){
                    this.DATA[i]=items.mont[i];
                  }
                }
                item.mont = this.DATA;
              });
            });
            console.log(this.table)
            this.Table = this.table;
          }
        });
          // for(var i=0;i<a.length;i++){
          //   for(var j=0;j<b.length;j++){
          //     if(b[j]>0){
          //       a[j]=a[j]+b[j];
          //     }
          //   }
          // }
      },
    monthly(items) {
      // console.log(items)
      ModalHelper.create(monthly, {
        auditPointId: items.auditPointId,time: items.time,hourOrDay: this.hourOrDay
      },{width:1000}).subscribe(res => {
        console.log(res)
      })
    },
    ModeChange(e) {
      if (e.target.value == 1) {
        this.value = true;
      } else if (e.target.value == 2) {
          this.value = false;
      }
      this.hourOrDay = e.target.value;
      // console.log(this.hourOrDay);
      this.getData();
    },
    onChange(date, time) {
      this.selectDayTime = time;
      this.getData();
    },
    onChange1(date, time) {
      this.startTime = time;
      this.hourOnChange();
    },
    onChange2(date, time) {
      this.endTime = time;
      this.hourOnChange();
    },
    onChange3(date, time) {
      this.SelectMonthTime = time;
      let mm = this.SelectMonthTime.split('-');    //把2020-11变成需要传输的样子：2020和11
      this.getDaysInMonth(mm[0],mm[1]);
      this.getData();
    },
    hourOnChange() {
      if(this.startTime!=undefined&&this.endTime!=undefined){
        this.timeRangeStartTime = this.startTime + ":00:00";
        this.timeRangeEndTime = this.endTime + ":00:00";
        let s=parseInt(this.startTime);
        let e=parseInt(this.endTime);
        this.mmmm = e-s;
        this.hour = [];
        for (let g=s;g<e;g++){
          this.i=g;
          this.hour.push(this.i);
        }
        console.log(this.hour)
        if (this.timeRangeStartTime==":00:00"||this.timeRangeEndTime==":00:00"){
          this.timeRangeStartTime = undefined;
          this.timeRangeEndTime = undefined;
          let i = 24;
          this.mmmm = i;
          this.hour = [];
          for (let a=0;a<i;a++){
            this.hour.push(a);
          }
        }
        this.getData();
      }else {

      }
    },
    function() {
      this.aData = new Date();
      console.log(this.aData)
      let dateValue = this.aData.getFullYear() + "-" + (this.aData.getMonth() + 1) + "-" + this.aData.getDate();
      let dateValue1 = this.aData.getFullYear() + "-" + (this.aData.getMonth() + 1);
      this.date = dateValue;
      this.SelectMonthTime = dateValue1;
      let mm = this.SelectMonthTime.split('-');    //把2020-11变成需要传输的样子：2020和11
      this.getDaysInMonth(mm[0],mm[1])
      this.selectDayTime = dateValue;
      this.getData();
    },
    getDaysInMonth(year,month){
      this.month = [];
      this.mon = [];
	  this.hour = [];
      let temp = new Date(year,month,0);
      let mm = temp.getDate(); //这里mm就是天数啦
      this.mmm = mm;
      for (let m=1;m<mm+1;m++){
        this.month.push(m);
        this.mon.push(undefined);
      }
      let i = 24;
      for (let a=0;a<i;a++){
        this.hour.push(a);
        this.Hour.push(undefined);
      }
      // console.log(this.mon)
    },
  }
}
</script>

<style scoped>
  .body {
    min-width: 60px;
    min-height: 50px;
  }
  .body1 {
    min-width: 160px;
    min-height: 50px;
  }
</style>