<template>
  <div>
    <div class="modal-header">
      <div class="modal-title">
        <a-icon type="medicine-box" style="margin-right:10px" />
        <span>{{l('239')}}</span>
      </div>
    </div>
    <div>
      <a-config-provider :locale="zh_CN">
        <a-card>
        <a-spin :spinning="isLoading">
          <a-table :columns="columns" :data-source="tableData" :customRow="customRow"
                   :rowKey="(record)=>record.id" :scroll="{x:800,y:scroll_x}" :pagination="false" size="small">
          </a-table>
        </a-spin>
        <a-pagination
            class="pagination"
            :total="totalItems"
            :show-total="total => `共有 ${totalItems} 条`"
            showSizeChanger
            showQuickJumper
            @change="onChangePage"
            @showSizeChange="showSizeChange"/>
        </a-card>
      </a-config-provider>
    </div>
    <div class="modal-footer" v-if="!isLook">
      <a-button @click="close">
        {{l('Cancel')}}
      </a-button>
    </div>
  </div>
</template>

<script>
import ModalComponentBase from '../../../../shared/component-base/modal-component-base';
import zh_CN from 'ant-design-vue/lib/locale/zh_CN';
import { PointCheckServiceProxy } from '../../../../shared/service-proxies';
import Editor from "../../../../components/editor/editor";
import moment from "moment";

let _this;
export default {
  name: "monthly-view",
  mixins: [ModalComponentBase],
  components: {
    Editor,
  },
  created() {
    this.fullData(); // 模态框必须,填充数据到data字段
    this.PointCheckServiceProxy = new PointCheckServiceProxy(this.$apiUrl, this.$api);
  },
  mounted() {
    this.getData();
  },
  data() {
    return {
      zh_CN,
      isEdit: false,//是否是编辑
      isLook: false,//是否是查看

      filterText: undefined,
      totalItems: 0, //总数
      // 当前页码
      pageNumber: 1,
      // 共多少页
      totalPages: 1,
      // 条数显示范围
      pagerange: [1, 1],
      // 显示条数
      pageSizeOptions: ["10", "20", "30", "40"],
      request: {
        maxResultCount: 10,
        skipCount: 0
      },

      tableData: [],
      columns: [{
        title: this.l('Punch_Card_Time'),
        width: 200,
        dataIndex: 'creationTime',
        align: 'center',
      },
        {
          title: this.l('Punch_Card_Person'),
          width: 150,
          dataIndex: 'creatorUserName',
          align: 'center',
        },
        {
          title: this.l('Points'),
          width: 150,
          dataIndex: 'auditPointName',
          align: 'center',
          ellipsis: true
        },
        {},
      ],
      isLoading: false, //加载中
      body: {
        auditPointId: undefined,
        selectTime: undefined,
        hourOrDay: undefined,
        filterText: undefined,
        sorting: undefined,
        maxResultCount: undefined,
        skipCount: undefined,
      },
    }
  },
  methods: {
    getData() {
      // console.log(this.time)
      // this.time ? moment(this.time).format('YYYY-MM-DD HH') : "";
      // console.log(this.selectTime)
      this.body.auditPointId = this.auditPointId;
      this.body.selectTime = this.time;
      this.body.hourOrDay = this.hourOrDay;
      this.body.filterText = this.filterText ? this.filterText : undefined;
      this.body.sorting = undefined;
      this.body.maxResultCount = this.request.maxResultCount;
      this.body.skipCount = this.request.skipCount;
      console.log(this.body)
      this.isLoading = true;
      this.PointCheckServiceProxy.monthViewDetails(
          this.body
      ).finally(() => {
        this.isLoading = false;
      }).then(res => {
        console.log(res);
        this.tableData = res.items;
        this.tableData.map(item=>{
          item.creationTime=item.creationTime?moment(item.creationTime).format("YYYY-MM-DD HH:mm:ss"):"";
        });
        // console.log(this.tableData)
        this.totalItems = res.totalCount;
        this.totalPages = Math.ceil(
            res.totalCount / this.request.maxResultCount
        );
        this.pagerange = [
          (this.pageNumber - 1) * this.request.maxResultCount + 1,
          this.pageNumber * this.request.maxResultCount,
        ];
      })
    },
    //分页
    onChangePage(page, pageSize) {
      this.selectedRowKey = [];
      this.pageNumber = page;
      this.request.skipCount = (page - 1) * this.request.maxResultCount;
      this.getData();
    },
    //正常分页展示形式，显示（共有 {0} 条）
    // showTotalFun() {
    // 	console.log(this.totalItems);
    //     return this.l(
    //         "GridFooterDisplayText",
    //         this.totalItems
    //     );
    // },
    showSizeChange(current, size) {
      this.selectedRowKey = [];
      this.pageNumber = 1;
      this.request.maxResultCount = size;
      this.request.skipCount = (this.pageNumber) * this.request.maxResultCount - size;
      this.getData();
    },
    //搜索框点击事件
    filteronchange() {
      this.pageNumber = 1;
      this.request.skipCount = 0;
      this.getData();
    },
    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
  }
}
</script>

<style scoped>
.pagination {
  margin: 10px auto;
  text-align: right;
}
</style>